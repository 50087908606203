import React from "react";
import { useNavigate } from "react-router-dom";
import fidgets, { getConfigById } from "../../constants/fidgetConfig";
import { FidgetId } from "../../constants/fidgetConsts";
import Utilities from "../../libs/Utilities";
import { Scrollbars } from 'react-custom-scrollbars'
import './LoadSaveModal.css';


enum Pages {
    LOAD,
    SAVE
}

/**
 * State :: 
 * 
 * Gonna need functions to 
 * 
 * Loading: 
 * - Get what's saved, given current Fidge (route)
 * - Load something into the Fidget on click
 * 
 * Saving: 
 * - Get what's in the current specific Fidget, and save it. 
 * 
 * 
 * My temptation is.... I should have everything (current Fidget state) in a high level state. 
 * 
 * This does NOT FEEL GREAT... but it might be a necessary evil.... 
 * 
 * I think that might be very helpful.
 * 
 */


// TODO @Marcel: Move this somewhere else :-) helper methods
export const fetchSavedStates = () => {

    // Grab them out of sync state

}

// TODO @Marcel: Globalize and enforce
export interface SavedPattern {
    fidgetId: FidgetId,
    data: any,
    name: string,
}

export interface SavePatternPending extends Omit<SavedPattern, 'name'> {

}

interface LoadSaveModalProps {

    currentFidgetState: SavePatternPending|null
    setCurrentPattern: React.Dispatch<React.SetStateAction<SavePatternPending | null>>
    closeModal: any

}

const fsKey = 'fidget_patterns'

export default (props: LoadSaveModalProps) => {

    const [pageView, setPageView] = React.useState(Pages.LOAD)

    const [savedPatters, setSavedPatters] = React.useState<SavedPattern[]>([])

    const navigate = useNavigate();

    // Save new state
    const [saveToName, setSaveToName] = React.useState('')


    const getSavedPatterns = () => {

        let patterns: SavedPattern[] = Utilities.fs.loadLocal(fsKey);

        // Bad state? 
        if (!patterns || patterns.length <= 0)
            return []

        // Filter anythign that's formatted poorly. 
        patterns = patterns.filter(pattern => {
            if (!pattern.fidgetId || !pattern.data || !pattern.name)
                return false;
            return true;
        })

        return patterns

    }

    const refreshSavedPatterns = React.useCallback(() => {

        // Grab the data out of save state
        const patterns = getSavedPatterns();

        console.warn("Loaded Patterns: ", patterns)

        // Set the data
        setSavedPatters(patterns)

    }, [setSavedPatters])

    // On mount; grab everything out of the state!
    React.useEffect(() => {

        refreshSavedPatterns()

    }, [refreshSavedPatterns])


    const savePatterns = (toSave: SavedPattern[]) => {

        Utilities.fs.saveLocal(toSave, fsKey)

    }

    // On Save: 
    const onSave = () => {

        if (!props.currentFidgetState) {
            window.alert("No pattern in state")
            return
        }
        if (saveToName.length <= 0) {
            window.alert("Please input a name")
            return
        }

        const config = getConfigById(props.currentFidgetState.fidgetId)
        if (!config?.canBeSaved) {
            window.alert("This fidget is ephemeral baby! No saving >:)")
            return;
        }


        // Grab the data out of the state
        let patterns = getSavedPatterns()

        savePatterns([...patterns, {...props.currentFidgetState, name: saveToName}])

        // Clear any needed data
        setSaveToName('')


        // Alert success 
        window.alert("Saved!")

        // Refresh 
        refreshSavedPatterns()

    }

    // TODO @Marcel: Patterns should prob have an id... 
    const onDelete = (patternIdx: number) => {

        // TODO @Marcel: Prob confirm LOL

        const filtered = savedPatters.filter((p, idx) => idx !== patternIdx)

        savePatterns(filtered)

        refreshSavedPatterns();

    }

    const onOpen = async (pattern: SavedPattern) => {

        // Get fidget
        const fidgetConfig = getConfigById(pattern.fidgetId)
        if (!fidgetConfig) {
            window.alert("Unable to find fidget.")
            return;
        }

        // Navigate
        // TODO @Marcel: This is a hack but... we need to wait for the nav to happen.
        await navigate(fidgetConfig.linkInfo.link)
        await new Promise(resolve => setTimeout(resolve, 500));
        

        // Load the fidget! :-)
        props.setCurrentPattern(pattern)

    }


    // Check if we can even save this
    const stateExists = props.currentFidgetState?.fidgetId;

    const canSave = props.currentFidgetState?.fidgetId ? getConfigById(props.currentFidgetState?.fidgetId)?.canBeSaved : false

    let saveBlocker: string[]|null = null
    if (!stateExists) {
        saveBlocker = ["Hey man, you've gotta put some stuff in the thing to save it man.", "Try putting more stuff in the thing!"]
    }
    else if (!canSave) {
        saveBlocker = ["Sorry man, this fidget is 100% ephemeral man.", "Try another if you wanna save!"]
    }


    return <div className='loadSaveModal'>

        <div className="header">
            <div className="closeWrapper" onClick={props.closeModal}>
                <img src='/resources/icons/Close.png'/>
            </div>
            <h1 className="title">Load or Save Pattern</h1>
            <div className="headerOptRow">
                <h2 onClick={() => {setPageView(Pages.LOAD)}}>Load</h2>
                <h2 onClick={() => {setPageView(Pages.SAVE)}}>Save</h2>
                </div>
        </div>

        <div className="bodyContent">

            {pageView === Pages.LOAD && <div className="loadBody">
                <Scrollbars autoHeight autoHide={false}>
                {savedPatters.map((pattern, idx) => {
                    return <div key={idx} className='patternPreview'>
                        <div className='savedNamePreview'>
                            <p>{pattern.name ?? 'Untitled'}</p>
                            <p>{pattern.fidgetId}</p>
                        </div>

                        <div>
                            <button onClick={() => onOpen(pattern)}>Open</button>
                            <button onClick={() => onDelete(idx)}>Delete</button>
                        </div>

                    </div>
                })}
                </Scrollbars>
            </div>}

            {pageView === Pages.SAVE && <div className='saveBody'>

                {!saveBlocker ? <>
                    <input
                        placeholder="Hip cool name >;)"
                        value={saveToName}
                        onChange={v => setSaveToName(v.target.value)}
                    />

                    <div className="buttonFooter">
                        <button>Cancel</button>
                        <button onClick={onSave}>Save</button>
                    </div>
                </> : <>

                    <div className="noSaveDisclaimer">{saveBlocker.map(p => <p>{p}</p>)}</div>
                
                </>}


            </div>}
        </div>


        

    </div>

}