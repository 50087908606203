export namespace R1 {
    export const getNumberSign = (input: number) => {
        return (input / Math.abs(input));
    }   
    export const interpolate = (x1: number, x2: number, t: number) => {

        // y= mx + b :-)
        const diff = x2 - x1

        if (t > 1)
            return x2
        if (t < 0)
            return x1

        return x1 + (x2 - x1) * t

    }
}