export namespace fs {
    export const saveLocal = (state: any, key: string) => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }
    export const loadLocal = (key: string): any => {
        const fromStorage = sessionStorage.getItem(key);
        if (fromStorage) 
            return JSON.parse(fromStorage);
        return null;
    }
}