
/**
 * WARNING:
 * - Removing a alterning a VALUE from here will lead to saved fidgets (data on browsers) being lost.
 */
export enum FidgetId {
    RIPPLE = 'ripple',
    BUBBLES = 'bubbles',
    FLINGER = 'flinger',
    PATHS = 'paths',
    TANGENT = 'tangent',
    SPIDER = 'spider',
    BUNGEE = 'bungee',
    SONIC = 'sonic',
    SONIC_FLINGER = 'sonic_flinger',
    OSCI = 'osci',
    HADRON = 'hadron',
    FISSION = 'fission',
    AMOEBA = 'amoeba',
    SHATTER = 'shatter',
    SINE = "sine"
}
